import * as React from "react";
import * as d3 from "d3";
import { Grid, alpha } from "@material-ui/core";
import { useEffect } from "react";
import Chance from "chance";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { tip as d3Tip } from "d3-v6-tip";
import { styled } from "@material-ui/core/styles";
import RingCalculator from "./RingCalculator";
import { TechnologyRadarLegendAccordion } from "./TechnologyRadarLegendAccordion";
import { TechnologyRadarContext } from "./TechnologyRadarContext";
import { Blip } from "./Blip";
import { RingName } from "./RingName";
import { Quadrant } from "./Quadrant";
import { RingData } from "./Ring";

const RadarContainer = styled("div")(({ theme }) => ({
  flexGrow: 1,
  "& circle": {
    fill: theme.palette.background.paper,
    opacity: 1,
  },
  "& text": {
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightBold,
    fill: theme.palette.text.primary,
  },
  "& text.line-text": {
    ...theme.typography.body1,
  },
  "& .d3-tip": {
    lineHeight: theme.typography.body1.lineHeight,
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(1),
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    pointerEvents: "none !important",
  },
  "& .d3-tip:after": {
    boxSizing: "border-box",
    display: "inline",
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    position: "absolute",
    pointerEvents: "none",
    color: theme.palette.background.paper,
  },
  "& .d3-tip.w:after": {
    content: '"\\25B6"',
    margin: "-11px 0 0 -2px",
    top: "50%",
    left: "100%",
  },
  "& .d3-tip.e:after": {
    content: '"\\25C0"',
    margin: "-10px -4px 0px 0px",
    top: "50%",
    right: "100%",
  },
  "& .d3-tip.n:after": {
    content: '"\\25BC"',
    margin: "-7px 0 0 0",
    top: "100%",
    left: 0,
    width: "100%",
    textAlign: "center",
  },
  "& g.blip-link": {
    cursor: "pointer",
  },
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const MIN_BLIP_WIDTH = 12;
const ANIMATION_DURATION = 1000;

type TechnologyRadarGraphProps = {
  activeQuadrant?: number | null;
  size?: number;
  xScale?: d3.ScaleLinear<number, number>;
  yScale?: d3.ScaleLinear<number, number>;
  margin?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  domainWidth?: number;
  domainHeight?: number;
  quadrants?: Quadrant[];
  rings?: RingData[];
};

const TechnologyRadarGraph: React.FC<TechnologyRadarGraphProps> = (props) => {
  const { activeQuadrant } = props;
  const { config, blips } = React.useContext(TechnologyRadarContext);
  const { size, margin, domainWidth, quadrants, rings } = config;
  const [expandedBlip, setExpandedBlip] = React.useState<string>("");
  const radarContainerRef = React.useRef(null);
  const radarRef = React.useRef(null);
  const center = Math.round(domainWidth / 2);
  const ringCalculator = new RingCalculator(
    rings.length,
    Math.round(domainWidth / 2)
  );

  let chance: Chance.Chance;

  quadrants.forEach((quadrant: Quadrant, index: number) => {
    // eslint-disable-next-line no-param-reassign
    quadrant.blips = blips.filter((blip: Blip) => blip.quadrantIndex === index);
  });

  function handleChangeExpandedBlip(newExpandedBlip: string) {
    setExpandedBlip((previousExpandedBlip) =>
      previousExpandedBlip === newExpandedBlip ? "" : newExpandedBlip
    );
  }

  function thereIsCollision(
    blip: Blip,
    coordinates: number[],
    allCoordinates: number[][]
  ) {
    return allCoordinates.some(
      (currentCoordinates: number[]) =>
        Math.abs(currentCoordinates[0] - coordinates[0]) < blip.width &&
        Math.abs(currentCoordinates[1] - coordinates[1]) < blip.width
    );
  }

  function toRadian(angleInDegrees: number) {
    return (Math.PI * angleInDegrees) / 180;
  }

  function findBlipCoordinates(
    blip: Blip,
    minRadius: number,
    maxRadius: number,
    startAngle: number,
    allBlipCoordinatesInRing: number[][]
  ): number[] {
    const maxIterations = 200;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    let coordinates = calculateBlipCoordinates(
      blip,
      chance,
      minRadius,
      maxRadius,
      startAngle
    );
    let iterationCounter = 0;
    let foundAPlace = false;

    while (iterationCounter < maxIterations) {
      if (thereIsCollision(blip, coordinates, allBlipCoordinatesInRing)) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        coordinates = calculateBlipCoordinates(
          blip,
          chance,
          minRadius,
          maxRadius,
          startAngle
        );
      } else {
        foundAPlace = true;
        break;
      }
      // eslint-disable-next-line no-plusplus
      iterationCounter++;
    }

    if (!foundAPlace && blip.width > MIN_BLIP_WIDTH) {
      // eslint-disable-next-line no-param-reassign
      blip.width -= 1;
      return findBlipCoordinates(
        blip,
        minRadius,
        maxRadius,
        startAngle,
        allBlipCoordinatesInRing
      );
    }
    return coordinates;
  }

  function calculateBlipCoordinates(
    blip: Blip,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    chance: Chance.Chance,
    minRadius: number,
    maxRadius: number,
    startAngle: number
  ) {
    const adjustX =
      Math.sin(toRadian(startAngle)) - Math.cos(toRadian(startAngle));
    const adjustY =
      -Math.cos(toRadian(startAngle)) - Math.sin(toRadian(startAngle));

    const radius = chance.floating({
      min: minRadius + blip.width / 2,
      max: maxRadius - blip.width / 2,
    });
    let angleDelta = (Math.asin(blip.width / 2 / radius) * 180) / Math.PI;
    angleDelta = angleDelta > 45 ? 45 : angleDelta;
    const angle = toRadian(
      chance.integer({ min: angleDelta, max: 90 - angleDelta })
    );

    const x = center + radius * Math.cos(angle) * adjustX;
    const y = center + radius * Math.sin(angle) * adjustY;

    return [x, y];
  }

  function triangle(
    blip: Blip,
    x: number,
    y: number,
    order: string,
    group: d3.Selection<SVGGElement, unknown, null, undefined>
  ) {
    return group
      .append("path")
      .attr(
        "d",
        "M412.201,311.406c0.021,0,0.042,0,0.063,0c0.067,0,0.135,0,0.201,0c4.052,0,6.106-0.051,8.168-0.102c2.053-0.051,4.115-0.102,8.176-0.102h0.103c6.976-0.183,10.227-5.306,6.306-11.53c-3.988-6.121-4.97-5.407-8.598-11.224c-1.631-3.008-3.872-4.577-6.179-4.577c-2.276,0-4.613,1.528-6.48,4.699c-3.578,6.077-3.26,6.014-7.306,11.723C402.598,306.067,405.426,311.406,412.201,311.406"
      )
      .attr(
        "transform",
        `scale(${blip.width / 34}) translate(${
          -404 + x * (34 / blip.width) - 17
        }, ${-282 + y * (34 / blip.width) - 17})`
      )
      .attr("class", order);
  }

  function circle(
    blip: Blip,
    x: number,
    y: number,
    order: string,
    group: d3.Selection<SVGGElement, unknown, null, undefined>,
    svg = null
  ) {
    return (group || svg)
      .append("circle")
      .attr("r", blip.width / 2)
      .attr("cx", x)
      .attr("cy", y)
      .attr("class", order);
  }

  function drawBlipInCoordinates(
    blip: Blip,
    coordinates: number[],
    order: string,
    quadrantGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tip: any
  ) {
    const x = coordinates[0];
    const y = coordinates[1];

    const group = quadrantGroup
      .append("g")
      .attr("class", "blip-link")
      .attr("id", `blip-link-${blip.number}`);

    if (blip.isNew) {
      triangle(blip, x, y, order, group);
    } else {
      circle(blip, x, y, order, group);
    }

    group
      .append("text")
      .attr("x", x)
      .attr("y", y + 4)
      .attr("class", "blip-text")
      .style("font-size", `${(blip.width * 10) / 22}px`)
      .attr("text-anchor", "middle")
      .text(blip.number);

    const blipListItem = d3.select(`#${blip.id}-panel-header`);

    const mouseOver = () => {
      d3.selectAll("g.blip-link").attr("opacity", 0.3);
      group.attr("opacity", 1.0);
      tip.show(blip.title, group.node());
    };

    const mouseOut = () => {
      d3.selectAll("g.blip-link").attr("opacity", 1.0);
      tip.hide().style("left", 0).style("top", 0);
    };

    blipListItem.on("mouseover", mouseOver).on("mouseout", mouseOut);
    group.on("mouseover", mouseOver).on("mouseout", mouseOut);

    const clickBlip = () => {
      handleChangeExpandedBlip(`${blip.id}-panel`);
    };
    blipListItem.on("click", clickBlip);
    group.on("click", clickBlip);
  }

  useEffect(() => {
    const tip = d3Tip()
      .attr("class", "d3-tip")
      .rootElement(radarContainerRef.current)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .html((d: any) => d)
      .offset(() => {
        if (d3.select(".quadrant-table.selected").node()) {
          const selectedQuadrant = d3.select(".quadrant-table.selected");
          if (
            selectedQuadrant.classed("first") ||
            selectedQuadrant.classed("fourth")
          ) {
            return [0, 10];
          }
          return [0, -11];
        }
        return [-10, 0];
      })
      .direction(() => {
        if (d3.select(".quadrant-table.selected").node()) {
          const selectedQuadrant = d3.select(".quadrant-table.selected");
          if (
            selectedQuadrant.classed("first") ||
            selectedQuadrant.classed("fourth")
          ) {
            return "e";
          }
          return "w";
        }
        return "n";
      });
    const svg = d3.select(radarRef.current);

    svg.selectAll("*").remove();
    svg
      .call(tip)
      .attr(
        "viewBox",
        `0 0 ${size + margin.left + margin.right} ${
          size + margin.top + margin.bottom
        }`
      );

    const radarGroup = svg
      .append("g")
      .style("transform", `translate(${margin.left}px, ${margin.top}px)`);

    quadrants.forEach((quadrant: Quadrant) => {
      const quadrantGroup = radarGroup
        .append("g")
        .attr("class", `quadrant-group quadrant-group-${quadrant.order}`);

      rings.forEach((ring: RingData, i: number) => {
        const arc = d3
          .arc()
          .innerRadius(ringCalculator.getRadius(i))
          .outerRadius(ringCalculator.getRadius(i + 1))
          .startAngle(toRadian(quadrant.startAngle))
          .endAngle(toRadian(quadrant.startAngle - 90));

        quadrantGroup
          .append("path")
          .attr("fill", alpha(quadrant.color, 1 - (i + 1) * 0.2))
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .attr("d", arc)
          .attr("class", `ring-arc-${ring.order}`)
          .attr("transform", `translate(${center}, ${center})`);
      });

      rings.forEach((ring: RingData, i: number) => {
        if (quadrant.order === "first" || quadrant.order === "fourth") {
          quadrantGroup
            .append("text")
            .attr("class", "line-text")
            .attr("y", center + 4)
            .attr(
              "x",
              center +
                (ringCalculator.getRadius(i) +
                  ringCalculator.getRadius(i + 1)) /
                  2
            )
            .attr("text-anchor", "middle")
            .text(ring.name);
        } else {
          quadrantGroup
            .append("text")
            .attr("class", "line-text")
            .attr("y", center + 4)
            .attr(
              "x",
              center -
                (ringCalculator.getRadius(i) +
                  ringCalculator.getRadius(i + 1)) /
                  2
            )
            .attr("text-anchor", "middle")
            .text(ring.name);
        }
      });

      rings.forEach((ring: RingData, i: number) => {
        const ringBlips = quadrant.blips.filter((blip) => blip.ringIndex === i);

        if (ringBlips.length === 0) {
          return;
        }

        const maxRadius = ringCalculator.getRadius(i + 1);
        const minRadius = ringCalculator.getRadius(i);

        const sumRing = ring.name
          .split("")
          .reduce((p, c) => p + c.charCodeAt(0), 0);
        const sumQuadrant = quadrant.name
          .split("")
          .reduce((p, c) => p + c.charCodeAt(0), 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        chance = new Chance(
          Math.PI *
            sumRing *
            ring.name.length *
            sumQuadrant *
            quadrant.name.length
        );
        const allBlipCoordinatesInRing: number[][] = [];

        ringBlips.forEach((blip) => {
          const coordinates = findBlipCoordinates(
            blip,
            minRadius,
            maxRadius,
            quadrant.startAngle,
            allBlipCoordinatesInRing
          );

          allBlipCoordinatesInRing.push(coordinates);
          drawBlipInCoordinates(
            blip,
            coordinates,
            quadrant.order,
            quadrantGroup,
            tip
          );
        });
      });
    });
  }, []);

  useEffect(() => {
    if (activeQuadrant != null) {
      const { order, startAngle } = quadrants[activeQuadrant];
      d3.selectAll(".quadrant-table").classed("selected", false);
      d3.selectAll(`.quadrant-table.${order}`).classed("selected", true);
      d3.selectAll(".blip-item-description").classed("expanded", false);

      const scale = 2;

      const adjustX =
        Math.sin(toRadian(startAngle)) - Math.cos(toRadian(startAngle));
      const adjustY =
        Math.cos(toRadian(startAngle)) + Math.sin(toRadian(startAngle));

      const translateX =
        ((-1 * (1 + adjustX) * size) / 2) * (scale - 1) +
        -adjustX * (1 - scale / 2) * size;

      const translateY =
        -1 * (1 - adjustY) * (size / 2) * (scale - 1) -
        ((1 - adjustY) / 2) * (1 - scale / 2) * size;

      const translateXAll =
        (((1 - adjustX) / 2) * size * scale) / 2 +
        ((1 - adjustX) / 2) * (1 - scale / 2) * size;
      const translateYAll = (((1 + adjustY) / 2) * size * scale) / 2;

      const moveRight = ((1 + adjustX) * (0.8 * window.innerWidth - size)) / 2;
      const moveLeft = ((1 - adjustX) * (0.8 * window.innerWidth - size)) / 2;

      const blipScale = 3 / 4;
      const blipTranslate = (1 - blipScale) / blipScale;
      const svg = d3.select("#radar");
      const translateYLineText =
        activeQuadrant === 0 || activeQuadrant === 1 ? 10 : -10;

      svg.style("left", `${moveLeft}px`).style("right", `${moveRight}px`);
      d3.select(`.quadrant-group-${order}`)
        .transition()
        .duration(ANIMATION_DURATION)
        .attr(
          "transform",
          `translate(${translateX},${translateY})scale(${scale})`
        );

      d3.selectAll(".line-text")
        .transition()
        .duration(ANIMATION_DURATION)
        .attr("transform", `translate(0, ${translateYLineText})`);

      d3.selectAll(`.quadrant-group-${order} .blip-link text`).each(
        // eslint-disable-next-line func-names
        function () {
          const x = d3.select(this).attr("x");
          const y = d3.select(this).attr("y");
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line react/no-this-in-sfc
          d3.select(this.parentNode)
            .transition()
            .duration(ANIMATION_DURATION)
            .attr(
              "transform",
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              `scale(${blipScale})translate(${blipTranslate * x},${
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                blipTranslate * y
              })`
            );
        }
      );

      d3.selectAll(".quadrant-group").style("pointer-events", "auto");

      d3.selectAll(`.quadrant-group:not(.quadrant-group-${order})`)
        .transition()
        .duration(ANIMATION_DURATION)
        .style("pointer-events", "none")
        .attr(
          "transform",
          `translate(${translateXAll},${translateYAll})scale(0)`
        );
    }
  }, [activeQuadrant, quadrants, size]);

  return (
    <RadarContainer id="radar-container" ref={radarContainerRef}>
      <Grid container spacing={5}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            order: { xs: 2, sm: 1 },
          }}
        >
          {quadrants.map((quadrant, quadrantIndex) => (
            <Box
              key={`${quadrant.name}-blip-list`}
              display={
                // eslint-disable-next-line no-nested-ternary
                activeQuadrant != null
                  ? activeQuadrant === quadrantIndex
                    ? "block"
                    : "none"
                  : "block"
              }
            >
              <Typography variant="h2" component="h2">
                {quadrant.name}
              </Typography>
              {rings.map((ring: RingData, ringIndex: number) => {
                const ringBlips = blips.filter(
                  (blip) =>
                    blip.quadrantIndex === quadrantIndex &&
                    blip.ringIndex === ringIndex
                );
                if (ringBlips.length === 0) {
                  return null;
                }
                return (
                  <Box ml={0} mt={2} key={ring.name}>
                    <RingName status={ring.name} />
                    <TechnologyRadarLegendAccordion
                      blips={ringBlips}
                      expandedBlip={expandedBlip}
                    />
                  </Box>
                );
              })}
              <Box m={3} />
            </Box>
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            order: { xs: 1, sm: 2 },
          }}
        >
          <svg id="radar" ref={radarRef} />
        </Grid>
      </Grid>
    </RadarContainer>
  );
};

export { TechnologyRadarGraph };
