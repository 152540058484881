import * as React from "react";

function useAccount(initialValue = "default"): {
  currentTab: string;
  handleTabsChange: (tab: string) => void;
} {
  const [currentTab, setCurrentTab] = React.useState<string>(initialValue);
  const handleTabsChange = React.useCallback((tab) => {
    setCurrentTab(tab);
  }, []);
  return {
    currentTab,
    handleTabsChange,
  };
}

export { useAccount };
