import * as React from "react";
import { Quadrant } from "./Quadrant";
import { Blip, BlipStatus } from "./Blip";

type TechnologyRadarContextProps = {
  blips: Blip[];
  config: {
    quadrantNames: string[];
    quadrants: Quadrant[];
    rings: {
      order: number;
      name: keyof typeof BlipStatus;
    }[];
    size: number;
    margin: {
      top: number;
      right: number;
      bottom: number;
      left: number;
    };
    domainWidth: number;
  };
};

const defaultConfig = {
  quadrantNames: [
    "tools",
    "techniques",
    "platforms",
    "languages-and-frameworks",
  ],
  size: 900,
  margin: { top: 40, right: 0, bottom: 40, left: 0 },
  domainWidth: 820,
  ringNames: ["Adopt", "Trial", "Assess", "Hold"],
  quadrants: [
    {
      id: "tools",
      name: "Tools",
      order: "first",
      index: 0,
      startAngle: 90,
      color: "#2489a0",
      blips: [],
    },
    {
      id: "techniques",
      name: "Techniques",
      order: "second",
      index: 1,
      startAngle: 0,
      color: "#84BFA4",
      blips: [],
    },
    {
      id: "platforms",
      name: "Platforms",
      order: "third",
      index: 2,
      startAngle: -90,
      color: "#e79c5a",
      blips: [],
    },
    {
      id: "languages-and-frameworks",
      name: "Languages & Frameworks",
      order: "fourth",
      index: 3,
      startAngle: -180,
      color: "#e75043",
      blips: [],
    },
  ],
  rings: [
    { order: 0, name: "Adopt" as keyof typeof BlipStatus },
    { order: 1, name: "Trial" as keyof typeof BlipStatus },
    { order: 2, name: "Assess" as keyof typeof BlipStatus },
    { order: 3, name: "Hold" as keyof typeof BlipStatus },
  ],
};

const TechnologyRadarContext = React.createContext<TechnologyRadarContextProps>(
  { config: defaultConfig, blips: [] }
);

export { TechnologyRadarContext, defaultConfig };
