import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Box } from "@material-ui/core";
import AuthGuard from "../AuthGuard";

interface PrivateRouteProps extends RouteComponentProps {
  component?: React.ElementType;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component, ...other }) => {
  const Component = component || Box;
  return (
    <AuthGuard>
      <Component {...other} />
    </AuthGuard>
  );
};

export { PrivateRoute };
