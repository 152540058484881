import * as React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { Box, Chip, Stack } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import LaunchIcon from "@material-ui/icons/Launch";
import { styled } from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";
import Divider from "@material-ui/core/Divider";
import { RepositoryData } from "../../types";
import { Link } from "../../../Link";

const LanguageDot = styled("span")(() => ({
  height: "12px",
  width: "12px",
  borderRadius: "50%",
  display: "inline-block",
}));

type RepositoryCardProps = {
  repository: RepositoryData;
};

const RepositoryCard: React.FC<RepositoryCardProps> = (
  props: RepositoryCardProps
) => {
  const { repository } = props;

  return (
    <Card
      sx={{
        flexGrow: 1,
        boxShadow: (theme) => theme.shadows[3],
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            aria-label="repository-name"
            src={repository.openGraphImageUrl}
          />
        }
        title={repository.nameWithOwner}
        subheader={
          repository.latestRelease ? (
            <Link underline="none" to={repository.latestRelease.url}>
              {repository.latestRelease.name}
            </Link>
          ) : null
        }
        subheaderTypographyProps={{ sx: { cursor: "pointer" } }}
      />
      <CardContent sx={{ minHeight: 125 }}>
        <Typography variant="body1" component="p" paragraph>
          {repository.description}
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {repository.topics.map((topic) => (
            <Chip
              sx={{ mr: 1, mb: 1 }}
              key={topic}
              size="small"
              variant="outlined"
              label={topic}
            />
          ))}
        </Box>
      </CardContent>
      <Stack
        direction="row"
        alignItems="center"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        sx={{ ml: 2, mb: 2, height: (theme) => theme.spacing(3.5) }}
      >
        {repository.primaryLanguage ? (
          <Box display="flex" alignItems="center">
            <LanguageDot
              sx={{
                backgroundColor: (theme) =>
                  repository.primaryLanguage.color ||
                  theme.palette.primary.main,
                marginRight: 1,
              }}
            />
            <Typography variant="subtitle1">
              {repository.primaryLanguage.name}
            </Typography>
          </Box>
        ) : null}
        {repository.stargazerCount >= 0 ? (
          <Box display="flex" alignItems="center">
            <StarIcon fontSize="small" sx={{ marginRight: 0.5 }} />
            <Typography variant="subtitle1">
              {repository.stargazerCount}
            </Typography>
          </Box>
        ) : null}
        <IconButton size="small" href={repository.url} target="_new">
          <LaunchIcon fontSize="small" />
        </IconButton>
      </Stack>
    </Card>
  );
};

export { RepositoryCard, RepositoryCardProps };
