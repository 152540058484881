import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { User } from "../../../AuthProvider";

type AccountGeneralSettingsProps = {
  user: User;
};

const AccountGeneralSettings: React.FC<AccountGeneralSettingsProps> = (
  props
) => {
  const { user } = props;

  return (
    user && (
      <Grid container spacing={3} {...props}>
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    p: 1,
                    border: (theme) => `1px dashed ${theme.palette.divider}`,
                    borderRadius: "50%",
                  }}
                >
                  <Avatar
                    src={user.avatar}
                    sx={{
                      height: 100,
                      width: 100,
                    }}
                  />
                </Box>
                <Typography
                  color="textPrimary"
                  sx={{ mt: 1 }}
                  variant="subtitle2"
                >
                  {user.name}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  {user.email}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <Card>
            <CardHeader title="Profile" />
            <Divider />
            <CardContent>
              <Grid container spacing={4}>
                <Grid item md={6} xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    label="Name"
                    name="name"
                    value={user.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    label="Email Address"
                    name="email"
                    type="email"
                    value={user.email}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2,
              }}
            >
              <Button color="primary" type="submit" variant="contained">
                Save Changes
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
    )
  );
};

export default AccountGeneralSettings;
