import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { useAuth } from "../AuthProvider";
import { Account } from "./Account";

const AccountView: React.FC<RouteComponentProps> = () => {
  const { user } = useAuth();
  return <Account user={user} />;
};

export { AccountView };
