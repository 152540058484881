type Blip = {
  title: string;
  id: string;
  groupId: string;
  linkId: string;
  status: string;
  ringIndex: number;
  quadrant: string;
  quadrantIndex: number;
  x: number | null;
  y: number | null;
  fillColor: string;
  slug: string;
  href: string;
  isNew: boolean;
  number: number;
  width: number;
  entries: BlipEntry[];
};

type BlipEntry = {
  content: {
    body: string;
  };
  date: string;
  status: keyof typeof BlipStatus;
};

enum BlipStatus {
  Adopt = "Adopt",
  Trial = "Trial",
  Assess = "Assess",
  Hold = "Hold",
}

export { Blip, BlipEntry, BlipStatus };
