import * as React from "react";
import { Router, RouteComponentProps, Redirect } from "@reach/router";
import { navigate, graphql } from "gatsby";
import { PrivateRoute } from "../components/PrivateRoute";
import { TechnologyRadar } from "../components/TechnologyRadar";
import { NotFound } from "../components/NotFound";
import { Login } from "../components/Login";
import { Loading } from "../components/Loading";
import { useAuth } from "../components/AuthProvider";
import { AccountView } from "../components/Account";
import { RepositoriesPage } from "../components/Repository";

const AuthCallbackPage: React.FC<RouteComponentProps> =
  (): JSX.Element | null => {
    const { isInitialized, isAuthenticated, redirectPathname } = useAuth();

    if (!isInitialized) {
      return <Loading />;
    }
    if (!isAuthenticated) {
      return <Redirect to="/login" noThrow />;
    }
    if (redirectPathname) {
      navigate(redirectPathname);
      return null;
    }
    /* Use gatsby's navigate, NOT @reach/router's. The navigate function
     * from @reach/router causes the 404 page to flash momentarily before navigating.
     */
    navigate("/");
    return null;
  };

type GithubQueryResult = {
  data: {
    search: {
      edges: {
        node: {
          id: string;
          name: string;
          url: string;
          description: string;
          homepageUrl: string;
          nameWithOwner: string;
          openGraphImageUrl: string;
          updatedAt: string;
          stargazerCount: number;
          primaryLanguage: {
            id: string;
            name: string;
            color: string;
          };
          repositoryTopics: {
            nodes: {
              topic: {
                name: string;
                id: string;
              };
              url: string;
              resourcePath: string;
              id: string;
            }[];
          };
          languages: {
            nodes: {
              color: string;
              id: string;
              name: string;
            }[];
          };
          releases: {
            nodes: {
              name: string;
              createdAt: string;
              url: string;
              isLatest: boolean;
            }[];
          };
        };
      }[];
    };
  };
};

const parseGithubQueryResult = (githubQueryResult: GithubQueryResult) => {
  const {
    data: {
      search: { edges },
    },
  } = githubQueryResult;
  return edges.map(({ node }) => ({
    ...node,
    topics: node.repositoryTopics.nodes.map(
      (topicNode) => topicNode.topic.name
    ),
    languages: node.languages.nodes,
    latestRelease: node.releases.nodes.filter((release) => release.isLatest)[0],
  }));
};

type AppPageQueryResult = {
  githubData: GithubQueryResult;
};

interface AppProps {
  data: AppPageQueryResult;
}

const App: React.FC<AppProps> = (props) => {
  const {
    data: { githubData },
  } = props;
  return (
    <Router basepath="/app">
      <AuthCallbackPage path="/" />
      <NotFound default />
      <Login path="login" />
      <PrivateRoute path="account">
        <AccountView default />
      </PrivateRoute>
      <PrivateRoute path="technology-radar">
        <TechnologyRadar default />
      </PrivateRoute>
      <PrivateRoute path="projects">
        <RepositoriesPage
          default
          repositories={parseGithubQueryResult(githubData)}
        />
      </PrivateRoute>
    </Router>
  );
};

export const query = graphql`
  query AppPageQuery {
    githubData {
      data {
        search {
          edges {
            node {
              id
              name
              url
              primaryLanguage {
                id
                name
                color
              }
              description
              homepageUrl
              nameWithOwner
              openGraphImageUrl
              updatedAt
              repositoryTopics {
                nodes {
                  topic {
                    name
                    id
                  }
                  url
                  resourcePath
                  id
                }
              }
              stargazerCount
              languages {
                nodes {
                  color
                  id
                  name
                }
              }
              releases {
                nodes {
                  name
                  createdAt
                  url
                  isLatest
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default App;
