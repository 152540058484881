import * as React from "react";
import { Grid } from "@material-ui/core";
import { RepositoryCard } from "./components/RepositoryCard";
import { RepositoryData } from "./types";

interface RepositoryGridProps {
  repositories: RepositoryData[];
}

const RepositoryGrid: React.FC<RepositoryGridProps> = ({ repositories }) => (
  <Grid container justifyContent="center" spacing={3}>
    {repositories.map((repository: RepositoryData) => (
      <Grid key={repository.id} item xs={12} sm={6}>
        <RepositoryCard repository={repository} />
      </Grid>
    ))}
  </Grid>
);

export { RepositoryGrid };
