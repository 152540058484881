class RingCalculator {
  numberOfRings: number;

  maxRadius: number;

  sequence: number[] = [0, 8, 3, 3, 2, 1, 1, 1];

  constructor(numberOfRings: number, maxRadius: number) {
    this.numberOfRings = numberOfRings;
    this.maxRadius = maxRadius;
  }

  sum(length: number): number {
    return this.sequence
      .slice(0, length + 1)
      .reduce((previous, current) => previous + current, 0);
  }

  getRadius(ring: number): number {
    const total = this.sum(this.numberOfRings);
    const sum = this.sum(ring);

    return (this.maxRadius * sum) / total;
  }
}

export default RingCalculator;
