import * as React from "react";
import { Grid, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { graphql, useStaticQuery } from "gatsby";
import { Router, RouteComponentProps, Link } from "@reach/router";
import { TechnologyRadarGraph } from "./TechnologyRadarGraph";
import { TechnologyRadarNavigation } from "./TechnologyRadarNavigation";
import {
  defaultConfig,
  TechnologyRadarContext,
} from "./TechnologyRadarContext";
import { Meta } from "../Meta";
import { Blip } from "./Blip";

type TechnologyRadarContentProps = RouteComponentProps & {
  quadrantId?: string | null;
};

const TechnologyRadarContent: React.FC<TechnologyRadarContentProps> = (
  props
) => {
  const { quadrantId } = props;
  const { config } = React.useContext(TechnologyRadarContext);
  const activeQuadrant =
    quadrantId != null ? config.quadrantNames.indexOf(quadrantId) : null;

  return (
    <>
      <Meta title="Technology Radar" />
      <Grid sx={{ mt: 5 }} container spacing={5}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            // sx={{ margin: 0, fontSize: "3em", fontWeight: "fontWeightLight" }}
            variant="h1"
            component="h1"
          >
            Technology Radar
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TechnologyRadarNavigation activeQuadrant={activeQuadrant} />
        </Grid>
      </Grid>
      {activeQuadrant != null ? (
        <Button component={Link} to="/app/technology-radar" variant="outlined">
          Reset
        </Button>
      ) : null}
      <TechnologyRadarGraph activeQuadrant={activeQuadrant} />
    </>
  );
};

interface TechnologyRadarProps extends RouteComponentProps {
  size?: number;
  margin?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
}

const TechnologyRadar: React.FC<TechnologyRadarProps> = (props) => {
  const { size = 900, margin = { top: 40, right: 0, bottom: 40, left: 0 } } =
    props;
  const data = useStaticQuery<{ blips: { nodes: Blip[] } }>(graphql`
    query TechRadarQuery {
      blips: allBlipsYaml {
        nodes {
          id
          title
          quadrant
          entries {
            date
            status
            content {
              body
            }
          }
        }
      }
    }
  `);

  const config = {
    ...defaultConfig,
    size,
    margin,
    domainWidth: size - margin.left - margin.right,
  };

  const blips: Blip[] = data.blips.nodes.reduce(
    (list: Blip[], element: Blip, index: number) => {
      const entry: Blip = {
        x: null,
        y: null,
        title: element.title,
        id: element.id,
        linkId: `${element.id}-link`,
        groupId: `${element.id}-group`,
        quadrantIndex: config.quadrantNames.indexOf(element.quadrant),
        quadrant: element.quadrant || "",
        ringIndex: config.ringNames.indexOf(element.entries[0].status),
        status: element.entries[0].status,
        fillColor: ["green", "blue", "red", "orange"][
          config.quadrantNames.indexOf(element.quadrant)
        ],
        slug: `/technology-radar/${element.quadrant}/${element.id}`,
        href: `/app/technology-radar/${element.quadrant}/${element.id}`,
        isNew: false,
        number: index + 1,
        width: 24,
        entries: element.entries,
      };
      list.push(entry);
      return list;
    },
    []
  );

  return (
    <TechnologyRadarContext.Provider
      value={{
        config,
        blips,
      }}
    >
      <Router>
        <TechnologyRadarContent path="/" />
        <TechnologyRadarContent path="/:quadrantId" />
      </Router>
    </TechnologyRadarContext.Provider>
  );
};
export { TechnologyRadar };
