import * as React from "react";
import MuiTooltip, {
  TooltipProps,
  tooltipClasses,
} from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { styled } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { BlipStatus } from "./Blip";

const helpMessageMap: { [key in BlipStatus]: string } = {
  Adopt:
    "We feel strongly that all teams should be adopting these technologies and use them when appropriate on new projects.",
  Trial:
    "Worth pursuing. It is important to understand how to build up this capability. Teams should try this technology on a project that can handle the risk.",
  Assess:
    "Worth exploring further with the goal of determining how it could effect your team and/or project.",
  Hold: "Proceed with caution.",
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  margin: theme.spacing(0.5),
}));

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.paper,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[12],
  },
}));

interface RingNameProps {
  status: keyof typeof BlipStatus;
}

const RingName: React.FC<RingNameProps> = ({ status }) => (
  <Box sx={{ mb: 1, display: "flex", alignItems: "center" }}>
    <Typography sx={{ m: 0, p: 0 }} component="h3" variant="h5">
      {status}
    </Typography>
    <Tooltip
      placement="top"
      arrow
      title={
        <Typography sx={{ m: 1, fontSize: ".7rem" }}>
          {helpMessageMap[status]}
        </Typography>
      }
    >
      <StyledIconButton size="small">
        <HelpOutlineIcon fontSize="inherit" />
      </StyledIconButton>
    </Tooltip>
  </Box>
);

export { RingName };
