import * as React from "react";
import { styled } from "@material-ui/core/styles";
import MuiAccordion, { AccordionProps } from "@material-ui/core/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { MDXRenderer } from "gatsby-plugin-mdx";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import { Blip } from "./Blip";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

interface Props {
  expandedBlip: string | false;
  blips: Blip[];
}

const TechnologyRadarLegendAccordion: React.FC<Props> = ({
  expandedBlip,
  blips,
}) => (
  <>
    {blips.map((blip) => (
      <Accordion
        square
        id={`blip-list-item-${blip.number}`}
        expanded={expandedBlip === `${blip.id}-panel`}
        key={`blip-list-item-${blip.number}`}
        className="blip-list-item"
      >
        <AccordionSummary
          aria-controls={`${blip.id}-panel-content`}
          id={`${blip.id}-panel-header`}
        >
          <Typography>{`${blip.number}. ${blip.title}`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MDXRenderer>{blip.entries[0].content.body}</MDXRenderer>
        </AccordionDetails>
      </Accordion>
    ))}
  </>
);

export { TechnologyRadarLegendAccordion };
