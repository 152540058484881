import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Box, Typography } from "@material-ui/core";
import { Meta } from "../Meta";
import { RepositoryData } from "./types";
import { RepositoryGrid } from "./RepositoryGrid";

type RepositoriesPageProps = RouteComponentProps & {
  repositories: RepositoryData[];
};

const RepositoriesPage: React.FC<RepositoriesPageProps> = (props) => {
  const { repositories } = props;

  return (
    <>
      <Meta title="Technology Radar" />
      <Box sx={{ mt: (theme) => theme.spacing(5) }}>
        <Typography variant="h1" component="h1" sx={{ mb: 2 }}>
          Projects
        </Typography>
        <RepositoryGrid repositories={repositories} />
      </Box>
    </>
  );
};

export { RepositoriesPage };
