import * as React from "react";
import { Theme, alpha } from "@material-ui/core/styles";
import { makeStyles, withStyles, createStyles } from "@material-ui/styles";
import { Tab, Tabs, TabsProps } from "@material-ui/core";

import { Link } from "@reach/router";

interface StyledTabsProps extends TabsProps {
  value: number | null;
}

const StyledTabs = withStyles({
  indicator: {
    display: "none",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
})((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

interface StyledTabProps {
  label: string;
  value: number;
  component: React.ElementType;
  to: string;
}

const quadrants = {
  1: {
    color: "#84BFA4",
  },
  2: {
    color: "#2489a0",
  },
  3: {
    color: "#e79c5a",
  },
  4: {
    color: "#e75043",
  },
};

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: theme.spacing(8),
      flexBasis: "50%",
      maxWidth: "50%",
      textTransform: "none",
      fontSize: theme.typography.button.fontSize,
      color: theme.palette.text.primary,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      borderWidth: 2,
      borderStyle: "solid",
      "&:hover": {
        opacity: 1,
      },
      "&:focus": {
        opacity: 1,
      },
      "&:nth-child(1)": {
        borderColor: alpha(quadrants[1].color, 0.5),
        borderTopLeftRadius: theme.shape.borderRadius,
      },
      "&:nth-child(1)::after": {
        content: `url("data:image/svg+xml,%3Csvg viewBox='0 0 450 450' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath fill='%23${quadrants[1].color.slice(
          1
        )}' d='M-120.5,1.4756993929725607e-14A120.5,120.5,0,0,1,-2.2135490894588406e-14,-120.5L-1.919633857663476e-14,-104.5A104.5,104.5,0,0,0,-104.5,1.279755905108984e-14Z' style='transform: translate(450px, 450px); opacity: 0.5;'%3E%3C/path%3E%3Cpath fill='%23${quadrants[1].color.slice(
          1
        )}' d='M-231,2.828934106030386e-14A231,231,0,0,1,-4.2434011590455786e-14,-231L-4.022964735199055e-14,-219A219,219,0,0,0,-219,2.6819764901327035e-14Z' style='transform: translate(450px, 450px); opacity: 0.5;'%3E%3C/path%3E%3Cpath fill='%23${quadrants[1].color.slice(
          1
        )}' d='M-341.5,4.182168819088211e-14A341.5,341.5,0,0,1,-6.273253228632316e-14,-341.5L-6.126295612734634e-14,-333.5A333.5,333.5,0,0,0,-333.5,4.084197075156423e-14Z' style='transform: translate(450px, 450px); opacity: 0.5;'%3E%3C/path%3E%3Cpath fill='%23${quadrants[1].color.slice(
          1
        )}' d='M-450,5.510910596163089e-14A450,450,0,0,1,-8.266365894244634e-14,-450L-8.192887086295793e-14,-446A446,446,0,0,0,-446,5.461924724197195e-14Z' style='transform: translate(450px, 450px); opacity: 0.5;'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")`,
        width: theme.spacing(8),
        height: theme.spacing(8),
        bottom: 0,
        right: 0,
        position: "absolute",
      },
      "&:nth-child(2)": {
        borderColor: alpha(quadrants[2].color, 0.5),
        borderTopRightRadius: theme.shape.borderRadius,
      },
      "&:nth-child(2)::after": {
        content: `url("data:image/svg+xml,%3Csvg viewBox='0 0 450 450' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath fill='%23${quadrants[2].color.slice(
          1
        )}' d='M-120.5,1.4756993929725607e-14A120.5,120.5,0,0,1,-2.2135490894588406e-14,-120.5L-1.919633857663476e-14,-104.5A104.5,104.5,0,0,0,-104.5,1.279755905108984e-14Z' style='transform: translate(450px, 450px); opacity: 0.5;'%3E%3C/path%3E%3Cpath fill='%23${quadrants[2].color.slice(
          1
        )}' d='M-231,2.828934106030386e-14A231,231,0,0,1,-4.2434011590455786e-14,-231L-4.022964735199055e-14,-219A219,219,0,0,0,-219,2.6819764901327035e-14Z' style='transform: translate(450px, 450px); opacity: 0.5;'%3E%3C/path%3E%3Cpath fill='%23${quadrants[2].color.slice(
          1
        )}' d='M-341.5,4.182168819088211e-14A341.5,341.5,0,0,1,-6.273253228632316e-14,-341.5L-6.126295612734634e-14,-333.5A333.5,333.5,0,0,0,-333.5,4.084197075156423e-14Z' style='transform: translate(450px, 450px); opacity: 0.5;'%3E%3C/path%3E%3Cpath fill='%23${quadrants[2].color.slice(
          1
        )}' d='M-450,5.510910596163089e-14A450,450,0,0,1,-8.266365894244634e-14,-450L-8.192887086295793e-14,-446A446,446,0,0,0,-446,5.461924724197195e-14Z' style='transform: translate(450px, 450px); opacity: 0.5;'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")`,
        width: theme.spacing(8),
        height: theme.spacing(8),
        transform: `rotate(90deg)`,
        bottom: 0,
        left: 0,
        position: "absolute",
      },
      "&:nth-child(3)": {
        borderColor: alpha(quadrants[3].color, 0.5),
        borderBottomLeftRadius: theme.shape.borderRadius,
      },

      "&:nth-child(3)::after": {
        content: `url("data:image/svg+xml,%3Csvg viewBox='0 0 450 450' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath fill='%23${quadrants[3].color.slice(
          1
        )}' d='M-120.5,1.4756993929725607e-14A120.5,120.5,0,0,1,-2.2135490894588406e-14,-120.5L-1.919633857663476e-14,-104.5A104.5,104.5,0,0,0,-104.5,1.279755905108984e-14Z' style='transform: translate(450px, 450px); opacity: 0.5;'%3E%3C/path%3E%3Cpath fill='%23${quadrants[3].color.slice(
          1
        )}' d='M-231,2.828934106030386e-14A231,231,0,0,1,-4.2434011590455786e-14,-231L-4.022964735199055e-14,-219A219,219,0,0,0,-219,2.6819764901327035e-14Z' style='transform: translate(450px, 450px); opacity: 0.5;'%3E%3C/path%3E%3Cpath fill='%23${quadrants[3].color.slice(
          1
        )}' d='M-341.5,4.182168819088211e-14A341.5,341.5,0,0,1,-6.273253228632316e-14,-341.5L-6.126295612734634e-14,-333.5A333.5,333.5,0,0,0,-333.5,4.084197075156423e-14Z' style='transform: translate(450px, 450px); opacity: 0.5;'%3E%3C/path%3E%3Cpath fill='%23${quadrants[3].color.slice(
          1
        )}' d='M-450,5.510910596163089e-14A450,450,0,0,1,-8.266365894244634e-14,-450L-8.192887086295793e-14,-446A446,446,0,0,0,-446,5.461924724197195e-14Z' style='transform: translate(450px, 450px); opacity: 0.5;'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")`,
        width: theme.spacing(8),
        height: theme.spacing(8),
        transform: `rotate(-90deg)`,
        top: 0,
        right: 0,
        position: "absolute",
      },

      "&:nth-child(4)": {
        borderColor: alpha(quadrants[4].color, 0.5),
        borderBottomRightRadius: theme.shape.borderRadius,
      },

      "&:nth-child(4)::after": {
        content: `url("data:image/svg+xml,%3Csvg viewBox='0 0 450 450' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath fill='%23${quadrants[4].color.slice(
          1
        )}' d='M-120.5,1.4756993929725607e-14A120.5,120.5,0,0,1,-2.2135490894588406e-14,-120.5L-1.919633857663476e-14,-104.5A104.5,104.5,0,0,0,-104.5,1.279755905108984e-14Z' style='transform: translate(450px, 450px); opacity: 0.5;'%3E%3C/path%3E%3Cpath fill='%23${quadrants[4].color.slice(
          1
        )}' d='M-231,2.828934106030386e-14A231,231,0,0,1,-4.2434011590455786e-14,-231L-4.022964735199055e-14,-219A219,219,0,0,0,-219,2.6819764901327035e-14Z' style='transform: translate(450px, 450px); opacity: 0.5;'%3E%3C/path%3E%3Cpath fill='%23${quadrants[4].color.slice(
          1
        )}' d='M-341.5,4.182168819088211e-14A341.5,341.5,0,0,1,-6.273253228632316e-14,-341.5L-6.126295612734634e-14,-333.5A333.5,333.5,0,0,0,-333.5,4.084197075156423e-14Z' style='transform: translate(450px, 450px); opacity: 0.5;'%3E%3C/path%3E%3Cpath fill='%23${quadrants[4].color.slice(
          1
        )}' d='M-450,5.510910596163089e-14A450,450,0,0,1,-8.266365894244634e-14,-450L-8.192887086295793e-14,-446A446,446,0,0,0,-446,5.461924724197195e-14Z' style='transform: translate(450px, 450px); opacity: 0.5;'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")`,
        width: theme.spacing(8),
        height: theme.spacing(8),
        transform: `rotate(180deg)`,
        top: 0,
        left: 0,
        position: "absolute",
      },
    },
    selected: {
      "&:nth-child(1)": {
        backgroundColor: alpha(quadrants[1].color, 0.5),
        borderColor: "transparent",
      },
      "&:nth-child(2)": {
        backgroundColor: alpha(quadrants[2].color, 0.5),
        borderColor: "transparent",
      },
      "&:nth-child(3)": {
        backgroundColor: alpha(quadrants[3].color, 0.5),
        borderColor: "transparent",
      },
      "&:nth-child(4)": {
        backgroundColor: alpha(quadrants[4].color, 0.5),
        borderColor: "transparent",
      },
    },
  })
)((props: StyledTabProps) => <Tab wrapped {...props} />);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(1),
    },
  })
);

type TechnologyRadarNavigationProps = {
  activeQuadrant: number | null;
};

const TechnologyRadarNavigation: React.FC<TechnologyRadarNavigationProps> = (
  props
) => {
  const classes = useStyles();
  const { activeQuadrant } = props;

  return (
    <div className={classes.root}>
      <StyledTabs value={activeQuadrant} aria-label="styled tabs example">
        <StyledTab
          label="Techniques"
          component={Link}
          to="/app/technology-radar/techniques"
          value={1}
        />
        <StyledTab
          label="Tools"
          value={0}
          component={Link}
          to="/app/technology-radar/tools"
        />
        <StyledTab
          label="Platforms"
          value={2}
          component={Link}
          to="/app/technology-radar/platforms"
        />
        <StyledTab
          label="Languages & Frameworks"
          component={Link}
          to="/app/technology-radar/languages-and-frameworks"
          value={3}
        />
      </StyledTabs>
    </div>
  );
};

export { TechnologyRadarNavigation };
