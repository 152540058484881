import * as React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Tab,
  Tabs,
  Divider,
} from "@material-ui/core";
import { User } from "../AuthProvider";
import { useAccount } from "./hooks/useAccount";
import { AccountGeneralSettings } from "./components";

const tabs = [{ label: "General", value: "general" }];

type AccountProps = {
  user: User;
};

export const Account: React.FC<AccountProps> = (props) => {
  const { user } = props;
  const { currentTab, handleTabsChange } = useAccount("general");

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Account
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Box sx={{ mt: 3 }}>
            {currentTab === "general" && <AccountGeneralSettings user={user} />}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Account;
